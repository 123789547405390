import {bus} from '@/helpers/bus';
import SalesGroupService from '@/services/sales.group.service';
import AgentService from '@/services/agent.service';

const service = new SalesGroupService();
const agentService  = new AgentService();

export default {
    name: 'SalesGroup',
    components: { },
    props: [],
    data() {
        return {
            agents: [],
            groups: [],

            group: {
                owner: null,
                title: '',
                agents: [],
                permissions: {
                    canDeleteAgent : false,
                    canModifyAgent : false,
                    canViewNewLeads : false,
                    canAssignNewLeads : false,
                }
            },

            perPage: 10,
            totalRows: 0,
            currentPage: 1,

            createModal: false,
            deleteModal: {
                Shown: false,
                GroupId: '',
                GroupName: '',
            },
        }
    },
    computed: {

    },
    async mounted() {
        this.listGroups();
    },

    methods: {
        async openModal() {
            this.createModal = true;
            this.listAgents();
        },

        async cancelModal() {
            this.createModal = false;
            this.deleteModal.Shown = false;
        },

        async createGroup(){
            if ( !this.group.owner ) {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.owner_empty',
                    description: 'error.owner_empty_description'
                });
                return;
            }

            if ( !this.group.agents || this.group.agents.length === 0) {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.agents_empty',
                    description: 'error.agents_empty_description'
                });
                return;
            }

            let post_group = {
                owner: this.group.owner.User._id,
                title: this.group.title,
                agents: this.group.agents.map( a => a.User._id),
                permissions: this.group.permissions
            };

            const res = await service.create(post_group);

            if ( res && !res.error ) {
                this.cancelModal();
                this.listGroups();
            } else {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.create_group',
                    description: 'error.create_group_description'
                });
                return;
            }
        },

        setOwner(agent){
            const exist = this.group.agents.find(u => u._id === agent._id);

            if ( exist ) {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.owner_already_added',
                    description: 'error.owner_already_added_description'
                });
                return;
            }

            this.group.owner = agent;
        },

        addAgent(agent){
            const exist = this.group.agents.find(u => u._id === agent._id);

            if ( this.group.owner && this.group.owner._id === agent._id ) {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.agent_is_owner',
                    description: 'error.agent_is_owner_description'
                });

                return;
            }

            if ( !exist ) {
                this.group.agents.unshift(agent);
            } else {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.agent_already_added',
                    description: 'error.agent_already_added_description'
                });
            }
        },

        delAgent(index){
            this.group.agents.splice(index, 1);
        },

        async listAgents(page) {
            this.currentPage = page || 1;

            let skip = this.perPage * (this.currentPage - 1);

            const res = await agentService.list(this.perPage, skip, this.searchVal);

            if (res && !res.error) {
                this.agents = res.data.agents;
                this.totalRows = res.data.count;
            }
        },

        async listGroups(page) {
            this.currentPage = page || 1;
            let skip = this.perPage * (this.currentPage - 1);

            const res = await service.list(this.perPage, skip, this.searchVal);
            if (res && !res.error) {
                this.groups = res.data.groups;
                this.totalRows = res.data.count;
            }
        },

        async setPerPage(num) {
            this.perPage = +(num);
            this.listAgents();
        },

        async remove(id) {
            let res = await service.remove(id);

            if (res && !res.error) {
                this.cancelModal();
                this.listGroups();
            }
        },
    }
}
