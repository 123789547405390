import IService from './iservice';
import config from "@/config";

const server_base = config[config.stage].ws_server;

class SalesGroupService extends IService{
    constructor(http){
        super(http);
    }

    async create(group){
        try{
            let response = await this.http.post(`${server_base}/api/group`, {group});
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async list(limit='', skip='', filter='') {
        try{
            let response = await this.http.get(`${server_base}/api/group?limit=${limit}&skip=${skip}&filter=${filter}`);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async get(id){
        try{
            let response = await this.http.get(`${server_base}/api/group/${id}`);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async edit(group){
        try{
            let response = await this.http.put(`${server_base}/api/group`, {group});
            return response.data;
        }catch (e) {
            return null;
        }
    }
    async remove(id){
        try{
            let response = await this.http.delete(`${server_base}/api/group/${id}`);
            return response.data;
        }
        catch (e) {
            return null;
        }
    }
}

export default SalesGroupService;
