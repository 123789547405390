import {bus} from '@/helpers/bus';
import Modal from '@/components/Modal/index.vue';
import GroupService from '@/services/sales.group.service';
import AgentService from '@/services/agent.service';

const agentService = new AgentService();
const groupService = new GroupService();

export default {
    name: 'details',
    components: {Modal},
    props: [],
    data() {
        return {
            agents: [],
            group: {
                Agents: [],
                Owner: '',
                Permissions: {},
                Title: '',
            },

            perPage: 10,
            totalRows: 0,
            currentPage: 1,
            searchVal: ''
        }
    },
    computed: {},
    mounted() {
        this.listAgents();
    },
    methods: {
        async edit() {
            if (!this.group.Owner) {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.owner_empty',
                    description: 'error.owner_empty_description'
                });
                return;
            }

            if (!this.group.Agents || this.group.Agents.length === 0) {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.agents_empty',
                    description: 'error.agents_empty_description'
                });
                return;
            }

            let post_group = {
                id: this.$route.params.id,
                Owner: this.group.Owner._id,
                Title: this.group.Title,
                Agents: this.group.Agents.map(a => a._id),
                Permissions: this.group.Permissions
            };

            const res = await groupService.edit(post_group);
            if (res && !res.error) {
                this.$router.push({name: 'groupList'});
            } else {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.create_group',
                    description: 'error.create_group_description'
                });
                return;
            }
        },

        setOwner(agent) {
            const exist = this.group.Agents.find(u => u._id === agent._id);

            if (exist) {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.owner_already_added',
                    description: 'error.owner_already_added_description'
                });
                return;
            }

            this.group.Owner = agent;
        },

        addAgent(agent) {
            const exist = this.group.Agents.find(u => u._id === agent._id);

            if (this.group.Owner && this.group.Owner._id === agent._id) {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.agent_is_owner',
                    description: 'error.agent_is_owner_description'
                });

                return;
            }

            if (!exist) {
                this.group.Agents.unshift(agent);
            } else {
                bus.$emit('GLOBAL_MESSAGE', {
                    title: 'error.agent_already_added',
                    description: 'error.agent_already_added_description'
                });
            }
        },

        delAgent(index) {
            this.group.Agents.splice(index, 1);
        },

        async listAgents(page) {
            this.currentPage = page || 1;

            let skip = this.perPage * (this.currentPage - 1);

            const res = await agentService.list(this.perPage, skip, this.searchVal);
            if (res && !res.error) {
                this.agents = res.data.agents;
                this.totalRows = res.data.count;
            }
        },
    },
    async beforeCreate() {
        const id = this.$route.params.id;
        if (!id || !id.length > 0) return;

        const res = await groupService.get(id);
        if (res && !res.error) {
            this.group = res.data;
        }
    }
}
